/* eslint-disable react/prop-types */
import React from 'react';
import { useFela, } from 'react-fela';
import PropTypes from 'prop-types';
import A11yDialog from '../A11yDialog/A11yDialog';
import Button from '../Button/Button';
import ServiceByMailRegistration from './ServiceByMailRegistration';
import IconClose from '../Icon/icons/IconClose';

export default function TagNotificationsRegistration({
  biAction,
  tag,
  onToggle,
  origin,
  feature,
  onResult,
}) {
  const [ showDialog, setShowDialog, ] = React.useState(false);
  const [ registrationStatus, setRegistrationStatus, ] = React.useState(null);
  const [ id, setRandomId, ] = React.useState(null);
  const { css, theme, } = useFela();

  React.useEffect(() => {
    setRandomId(`tagAlerts_${Math.floor(Math.random() * 100)}`);
  }, []);

  function onRegistrationSuccess({ email, status, }) {
    if (typeof onResult === 'function') {
      onResult(true);
    }
    setShowDialog(true);
    setRegistrationStatus(status);
    biAction({
      actionCode: 9,
      featureType: 'Content',
      feature: feature || 'Tag Alert',
      segmentId: tag && tag.contentId,
      newsletterEmail: email,
      segmentName: tag && tag.contentName,
    });
  }

  function onRegistrationError(data) {
    if (typeof onResult === 'function') {
      onResult(false);
    }
    setShowDialog(true);
    setRegistrationStatus(null);
  }
  if (!id) {
    return null;
  }

  const buttonI18n = theme.serviceByMailI18n.confirmationButton[registrationStatus]
    || theme.serviceByMailI18n.confirmationButton.default;

  return (
    <div
      className={css({
        fontFamily: theme.fontStacks[theme.serviceByMailStyle.fontStack],
        color: theme.color('neutral', '-1'),
      })}
    >
      <div
        id={`${id}_form`}
        className={showDialog ? css({ opacity: 0, }) : null}
      >
        <ServiceByMailRegistration
          title={`${theme.serviceByMailI18n.tagAlertServiceTitle}${tag.contentName}`}
          resourceId={tag.contentId}
          onRegistrationSuccess={onRegistrationSuccess}
          onRegistrationError={onRegistrationError}
          onCancel={onToggle}
          type="tag"
        />
      </div>
      <A11yDialog
        appendTo={id}
        elementToHide={`${id}_form`}
        isVisible={showDialog}
        overlayBgColor="rgba(0,0,0,0)"
        onClose={() => {
          if (typeof onToggle === 'function') {
            onToggle(registrationStatus);
          }

          setShowDialog(false);
        }}
        containerMiscStyles={{
          width: '100%',
          height: [ { from: 's', value: '100%', }, ],
          top: [ { until: 's', value: '0', }, ],
          start: [ { until: 's', value: '0', }, ],
          end: [ { until: 's', value: 'unset', }, ],
          bottom: [ { until: 's', value: 'unset', }, ],
          transform: [ { until: 's', value: 'none', }, ],
        }}
        render={({ isVisible, handleClose, isModal, }) => (
          <div
            data-test="tagNotificationRegistrationResultDialog"
            className={css({
              height: '100%',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              backgroundColor: theme.color('alerts', 'bg'),
              extend: [
                theme.mq({ until: 's', }, { padding: '4rem 2rem', }),
                theme.mq({ from: 's', }, { padding: '2rem', }),
              ],
            })}
          >
            <button
              onClick={() => {
                if (typeof onToggle === 'function') {
                  onToggle(registrationStatus);
                }
              }}
              type="button"
              className={css({
                top: '1rem',
                end: '1rem',
                position: 'absolute',
              })}
            >
              <IconClose size={3} />
            </button>
            <div
              className={css({
                fontWeight: '700',
                extend: [
                  theme.type(-1),
                ],
              })}
            >
              {[ 'success', 'exist', 'pending', ].includes(registrationStatus) ? (
                <>
                  {theme.serviceByMailI18n.confirmationMessageTag[registrationStatus](tag.contentName)}
                  {theme.serviceByMailI18n.confirmationMessageSecondRow[registrationStatus] && (
                    <span className={css({ display: 'block', marginTop: '1rem', })}>
                      {theme.serviceByMailI18n.confirmationMessageSecondRow[registrationStatus]}
                    </span>
                  )}
                </>
              ) : (
                theme.serviceByMailI18n.registrationfailureDefaultMessage
              )}
            </div>
            {buttonI18n ? (
              <Button
                href={buttonI18n.href}
                onClick={handleClose}
                variant="neutral"
                miscStyles={{
                  type: -2,
                  marginTop: '1rem',
                }}
              >
                {buttonI18n.text}
              </Button>
            ) : null}
          </div>
        )}
      />
      <div id={id} />
    </div>
  );
}

TagNotificationsRegistration.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tag: PropTypes.object.isRequired,
  /** Callback to execute when cancel button is pressed, If omitted, the Cancel button wont appear * */
  onToggle: PropTypes.func,
};

TagNotificationsRegistration.defaultProps = {
  onToggle: null,
};
