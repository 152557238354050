export default function isOmnyCustomPlayer(context) {
  if (
    context
    && context.kind === 'Embed'
    && context.inputTemplate === 'OmnyStudio'
  ) {
    return context.settings
      ? !context.settings.useOmnyEmbed
      : false;
  }
  return false;
}
