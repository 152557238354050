// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { CookieUtils, } from '@haaretz/htz-user-utils';
import IconPlus from '../Icon/icons/IconPlus';
import Button from '../Button/Button';
import EventTracker from '../../utils/EventTracker';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useTldrData from '../../hooks/Page/useTldrData';
import HtmlNode from '../HtmlNode/HtmlNode';
import { useUser, } from '../User/UserDispenser';
import DisplayFomoToolTip from '../FomoToolTip/DisplayFomoToolTip';

type CloseButtonType = {
  isClosed: boolean,
  title: string,
};

type TextType = { text: string, }
type TldrVisualType = { isPusr: boolean, }

function CloseButton({ isClosed, title, }: CloseButtonType): React.Node {
  const { css, theme, } = useFela();
  const { tldrIconSize, } = theme.tldrStyle || {};

  const Icon = IconPlus;
  return (
    <EventTracker>
      {({ biAction, }) => (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '6rem',
            minWidth: '6rem',
            backgroundColor: theme.color('tldr', 'buttonBg'),
            ':hover': { backgroundColor: theme.color('tldr', 'buttonBgHover'), },
            ':active': { backgroundColor: theme.color('tldr', 'buttonBgActive'), },
            ':focus': {
              backgroundColor: theme.color('tldr', 'buttonBgHover'),
              outline: 'none',
            },
          })}
        >
          <Icon
            size={4}
            color={[ 'tldr', 'buttonIcon', ]}
            miscStyles={{
              transform: `rotate(${isClosed ? 0 : -45}deg)`,
              transitionDuration: '0.2s',
              transitionProperty: 'transform',
            }}
          />
        </div>
      )}
    </EventTracker>
  );
}

function TldrHeader({ text, }: TextType): React.Node {
  const { css, theme, } = useFela();

  return (
    <div
      className={css({
        fontWeight: 700,
        display: 'inline-block',
        flexGrow: 1,
        textAlign: 'start',
        padding: '1rem 2rem',
        extend: [ theme.type(1, { untilBp: 'xl', }), ],
      })}
    >
      {text}
    </div>
  );
}


function TldrPusr({ isPusr, }: TldrVisualType): React.Node {
  const { css, theme, } = useFela();
  const [ isClosed, setClosed, ] = React.useState(true);
  const tldrData = useTldrData();

  const { tldr, tldrWordCount, } = tldrData || {};

  const content = theme.tldrDefaultContent(tldrWordCount || 100);
  const promotionLink = theme.tldrPromotionLink;
  const { tldrHeader, subscribeButtonStyle, tldrBodyFontWeight, tldrPromotion, } = theme.tldrStyle || {};
  const pusrTitle = (tldrWordCount == null || tldrWordCount === 0) ? content.titleDefault : content.titlePusr;
  const title = isPusr ? pusrTitle : content.titleRegular;


  return (
    <EventTracker>
      {({ biAction, }) => (
        <aside
          className={css({
            minWidth: '80%',
            color: theme.color('bodyText', 'base'),
            backgroundColor: theme.color('tldr', 'bg'),
            marginTop: '1rem',
            marginBottom: '2rem',
          })}
        >
          <button
            type="button"
            onClick={() => {
              setClosed(prevIsClosed => {
                if (prevIsClosed) {
                  biAction({
                    actionCode: 162,
                    feature: 'TLDR',
                    featureType: 'Content',
                    campaignName: 'TLDR',
                    campaignDetails: title,
                  });
                }

                return !prevIsClosed;
              });
            }}
            className={css({
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              ...tldrHeader ? { flexDirection: tldrHeader.flexDirection, } : {},
            })}
          >
            <CloseButton isClosed={isClosed} title={title} />
            <TldrHeader text={title} />
          </button>
          {!isClosed
            && (isPusr ? (
              <div
                className={css({
                  padding: '1.5rem',
                  margin: 'auto',
                })}
              >
                {tldr.map(val => (
                  <HtmlNode
                    miscStyles={{
                      margin: '1rem 0.5rem',
                    }}
                    {...val}
                  />
                ))}
                <DisplayFomoToolTip impressionData={{ feature: 'TLDR',
                  featureType: 'Marketing',
                  campaignName: 'fomo',
                  campaignDetails: 'TLDR', }}
                >
                  <div className={css({ marginTop: '1rem',
                    paddingTop: '2rem',
                    borderTop: `1px solid ${theme.color('neutral', '-4')}`,
                    textAlign: 'center',
                    ...theme.type(1),
                  })}
                  >
                    <div className={css({ fontWeight: '700', marginBottom: '2rem', })}>{theme.fomoToolTipI18n.text.tldr}</div>
                    <Button
                      onClick={() => biAction({
                        actionCode: 3,
                        feature: 'TLDR',
                        featureType: 'Marketing',
                        campaignName: 'fomo',
                        campaignDetails: 'TLDR',
                      })
                      }
                      href={theme.fomoToolTipI18n.href.tldr}
                      variant="salesOpaque"
                      fontSize={0}
                      miscStyles={{
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        paddingInlineStart: '3rem',
                        paddingInlineEnd: '3rem',
                        marginBottom: '2.5rem',
                        borderRadius: '50px',
                      }}
                    >
                      {theme.fomoToolTipI18n.text.button}
                    </Button>
                  </div>
                </DisplayFomoToolTip>
              </div>

            ) : (
              <div>
                <p
                  className={css({
                    padding: '1rem 1.5rem',
                    margin: '1rem 0.5rem',
                  })}
                >
                  {content.text}
                </p>

                <div
                  className={css({
                    padding: '0.5rem 1.5rem',
                    ...tldrPromotion ? { textAlign: tldrPromotion.textAlign, } : {},
                  })}
                >
                  <Button
                    onClick={() => biAction({
                      actionCode: 3,
                      featureType: 'Content',
                      feature: 'TLDR',
                      campaignName: 'TLDR',
                      campaignDetails: content.titleClosed,
                    })
                    }
                    href={promotionLink}
                    variant="salesOpaque"
                    fontSize={-1}
                    miscStyles={{
                      marginBottom: subscribeButtonStyle?.marginBottom,
                      ...subscribeButtonStyle?.paddingTop ? { paddingTop: subscribeButtonStyle.paddingTop, } : {},
                      ...subscribeButtonStyle?.paddingBottom ? { paddingBottom: subscribeButtonStyle.paddingBottom, } : {},
                    }}
                  >
                    {content.purchaseButton}
                  </Button>
                </div>
              </div>
            ))}
        </aside>
      )}
    </EventTracker>

  );
}

export default function Tldr(): React.Node {
  const tldrData = useTldrData();
  const { user, } = useUser();

  const { tldr, tldrWordCount, } = tldrData || {};

  const [ isPusr, setPusr, ] = React.useState(false);
  const isWebView = useWebViewChecker();

  React.useEffect(() => {
    setPusr(user.type === 'paying');
  }, [ user.type, ]);

  if (!(tldr?.length && tldrWordCount)) return null;

  return (tldr && tldr.length > 0) ? <TldrPusr isPusr={isWebView || isPusr} /> : null;
}
