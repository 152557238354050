// @flow
import * as React from 'react';
import { useGetComponent, } from '@haaretz/htz-components';
import RainbowSlot from './RainbowSlot';
import type { SlotPropsType, } from './RainbowSlot';
import useIsRainbowEnabled from '../../hooks/Page/useIsRainbowEnabled';
import usePreview from '../../hooks/Page/usePreview';
import useIsBlock from '../../hooks/useIsBlock';

type PaywallSlotPropsType = {
  shouldHideContent?: boolean,
}

RainbowPaywallSlot.defaultProps = {
  shouldHideContent: true,
  onToolRendered: null,
};

export default function RainbowPaywallSlot({ id, onToolRendered, }: SlotPropsType & PaywallSlotPropsType) {
  const getComponent = useGetComponent();
  const { isPreview, } = usePreview();
  const isRainbowEnabled = useIsRainbowEnabled();
  const isArticleBlocked = useIsBlock();

  const [ showLoader, setShowLoader, ] = React.useState(true);

  const IconLoader = getComponent('loaderIcon');

  const onPaywallToolRendered = React.useCallback(tool => {
    if (tool) {
      setShowLoader(false);
    }

    onToolRendered && onToolRendered(tool);
  }, [ onToolRendered, ]);

  if (!isArticleBlocked || isPreview || !isRainbowEnabled) {
    return null;
  }

  return (
    <>
      { showLoader
        ? (

          <IconLoader
            size={15}
            color="primary"
            miscStyles={{ width: '100%', marginTop: '15rem', marginBottom: '25rem', }}
          />
        )
        : null
      }
      <RainbowSlot id={id} onToolRendered={onPaywallToolRendered} />
    </>
  );
}
