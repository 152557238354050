/* global window document */
import React, { useState, } from 'react';
import config from 'config';

import { useRouter, } from 'next/router';
import useDarkModeChecker from '../../hooks/useDarkModeChecker';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import usePrint from '../../hooks/Page/usePrint';

const siteNumber = config.has('siteNumber') ? config.get('siteNumber') : 80;
const isHDC = siteNumber === 85;


export default function TrinityPlayer({ biImpression, biAction, user, }) {
  const ref = React.useRef(null);
  const isDarkMode = useDarkModeChecker();
  const [ isOpen, setIsOpen, ] = useState(false);
  const isWebView = useWebViewChecker();
  const router = useRouter();
  const { isPrint, } = usePrint();

  const isPodcastArticle = router.asPath.includes('/podcasts/');

  React.useEffect(() => () => {
    if (ref.current?.outerHTML) {
      ref.current.outerHTML = null;
    }
  }, []);


  React.useEffect(() => {
    if (isWebView && isPodcastArticle) return;
    if (!ref.current) return;
    if (!user?.type) return;
    if (isOpen) return;

    const isSubscriber = (user || {}).type === 'paying';
    let src = `https://trinitymedia.ai/player/trinity/${isHDC ? '2900001646' : '2900005985'}/?pageURL=${window.location.href}`;

    const currentScript = ref.current.querySelector(`script[src^="${src}"]`);
    src = `${src}&subscriber=${isSubscriber ? 1 : 0}&isDarkMode=${isDarkMode ? 1 : 0}${isDarkMode ? '&themeId=312' : ''}`;

    if (currentScript) {
      currentScript.src = src;
    }
    else {
      const script = document.createElement('script');
      script.src = src;
      script.setAttribute('type', 'text/javascript');
      ref.current.appendChild(script);

      biImpression && biImpression({
        feature: 'Trinity Text to Speech',
        featureType: 'Content',
        campaignName: 'Trinity Text to Speech',
      });
    }
    setIsOpen(true);
  }, [ user, biImpression, isDarkMode, isOpen, isWebView, isPodcastArticle, ]);

  React.useEffect(() => {
    const handleTrinityMessage = event => {
      if (window.TRINITY_PLAYER == null || window.TRINITY_PLAYER.constants == null) return;

      const isEventReady = !!event.data && !!event.data.value && !!event.data.value.action;
      if (isEventReady && (event.data.value.action === 'resumed' || event.data.value.action === 'playClicked')) {
        biAction && biAction({
          actionCode: 161,
          feature: 'Trinity Text to Speech',
          featureType: 'Content',
          campaignName: 'Trinity Text to Speech',
          campaignDetails: event.data.value.action,
        });
      }
    };

    window.addEventListener('message', handleTrinityMessage);

    return () => {
      window.removeEventListener('message', handleTrinityMessage);
    };
  }, [ biAction, ]);

  if (isWebView && isPodcastArticle) return null;
  if (isPrint) return null;

  return <div ref={ref} data-test="trinity-player" />;
}
