import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import H from '../AutoLevels/H';
import Tag from './Tag';
import SlideinBox from '../Transitions/SlideinBox';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
import { useEventTracker, } from '../../utils/EventTracker';
import TagNotificationsRegistration from '../ServiceByMailRegistration/TagNotificationsRegistration';
import useTagsData from '../../hooks/Page/useTagsData';

const propTypes = {
  /**
   * A special property holding miscellaneous CSS values that
   * trumps all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,

  isPhotoBlog: PropTypes.bool,
};

const defaultProps = {
  miscStyles: null,
  isPhotoBlog: false,
};

/*
 * Returns an inline list of the article's related tags.
 */
function Tags({ miscStyles, isPhotoBlog, }) {
  const tags = useTagsData();
  const tagsList = tags || [];

  const { css, theme, } = useFela(miscStyles);
  const [ registed, setRegistered, ] = React.useState({});
  const [ selectedTag, setSelectedTag, ] = React.useState(null);
  const { biAction, } = useEventTracker();
  const hasValidItems = Array.isArray(tagsList) && tagsList.length > 0;

  if (!hasValidItems) {
    return null;
  }

  const tagsListLimit = tagsList.slice(0, 4);

  return (
    <WebViewExclude>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          fontFamily: theme.fontStacks[theme.framedFont],
          marginTop: '2rem',
          extend: [
            theme.type(-1),
            ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
          ],
        })}
        data-test="tags"
      >
        <H
          className={css({
            color: isPhotoBlog ? null : theme.color('tags', 'header'),
            marginEnd: '1rem',
          })}
        >
          {theme.tagsElementI18n.prefix}
        </H>
        <ul className={css({
          display: 'flex',
          flexWrap: 'wrap',
          flexlDirection: 'column',
        })}
        >
          {tagsListLimit.map((tag, i) => (
            <Tag
              {...tag}
              key={tag.name}
              registered={registed[tag.name]}
              isPhotoBlog={isPhotoBlog}
              onIconClick={() => {
                setSelectedTag(tag);

                if (selectedTag) {
                  setSelectedTag(null);

                  if (selectedTag?.name !== tag.name) {
                    setTimeout(() => setSelectedTag(tag), 0);
                  }
                }
                else {
                  setSelectedTag(tag);
                }
              }}
            />
          ))}
        </ul>
        <SlideinBox
          show={Boolean(selectedTag)}
          duration={2}
          focus
          maxHeight={100}
          persistTopMargin
        >
          <TagNotificationsRegistration
            tag={{ contentName: selectedTag?.name, contentId: selectedTag?.contentId, }}
            biAction={biAction}
            onToggle={() => setSelectedTag(null)}
            feature="Tag Alert - article page"
            onResult={result => setRegistered({ ...registed, [selectedTag?.contentId]: true, })}
          />
        </SlideinBox>
      </div>
    </WebViewExclude>
  );
}

Tags.propTypes = propTypes;
Tags.defaultProps = defaultProps;

export default Tags;
