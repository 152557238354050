// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import HtzLink from '../HtzLink/HtzLink';
import IconAlert from '../Icon/icons/IconAlert';
import IconCheckRegular from '../Icon/icons/IconCheckRegular';

type TagProps = {
  name: string,
  url: string,
  registered: boolean,
  onIconClick: () => void,
  isPhotoBlog: boolean,
};

export default function Tag({
  name,
  url,
  registered,
  onIconClick,
  isPhotoBlog, }: TagProps) {
  const { css, theme, } = useFela();

  return (
    <li
      className={css({
        marginTop: '1.5rem',
        marginEnd: '3rem',
        display: 'flex',
        alignItems: 'center',
      })}
      key={name}
    >
      {!registered ? (
        <button
          type="button"
          onClick={() => {
            onIconClick();
          }}
          data-test="tagAlertButton"
        >
          <IconAlert
            miscStyles={{
              color: theme.color('tags', 'icon'),
              marginInlineEnd: '0.25rem',
            }}
            size={3.5}
          />
        </button>
      ) : (
        <IconCheckRegular
          miscStyles={{
            marginInlineEnd: '0.5rem',
            color: theme.color('tags', 'iconSuccess'),
          }}
          size={3.5}
        />
      )}
      <HtzLink
        href={url}
        content={(
          <span
            className={css({
              color: isPhotoBlog ? theme.color('neutral', '-10') : theme.color('tags', 'tag'),
              extend: [
                theme.type(0, { until: 's', }),
              ],
            })}
          >
            {name}
          </span>
        )}
      />
    </li>

  );
}
