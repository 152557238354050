// @flow

import { useQuery, } from 'react-apollo';
import { TLDR_QUERY, } from '@haaretz/graphql';
import useArticleId from './useArticleId';
import useLiveBlogQueryVariables from './useLiveBlogQueryVariables';

export default function useTldrData() {
  const articleId = useArticleId();
  const queryVariables = useLiveBlogQueryVariables(articleId);

  const { data, loading, error, } = useQuery(TLDR_QUERY, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,

  });

  if (loading) return null;
  if (error) {
    console.error('TLDR error', error);
    return null;
  }

  if (!data) return null;

  return data.Page?.slots?.article;
}
