// @flow
import React from 'react';
import PropTypes, { oneOf, } from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, parseComponentProp, } from '@haaretz/htz-css-tools';
import { responsivePropBaseType, } from '../../propTypes/responsivePropBaseType';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import Button from '../Button/Button';
import IconAlefLogo from '../Icon/icons/IconAlefLogo';
import IconTheMarker from '../Icon/icons/IconTheMarker';
import H from '../AutoLevels/H';
import Section from '../AutoLevels/Section';
import BlockLink from '../BlockLink/BlockLink';
import AboveBlockLink from '../BlockLink/AboveBlockLink';
import useOneTime from '../../hooks/useOneTime';

const selectVariants = oneOf([ 'primary', 'primaryInverse', ]);

const LinkElementStyle = ({ theme, miscStyles, variant, }) => ({
  width: '100%',
  marginBottom: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  extend: [
    // Trump all other styles with those defined in `miscStyles`
    parseComponentProp(undefined, variant, theme.mq, setVariant, theme.color),
  ],
});

const titleStyle = ({ theme, }) => ({
  paddingTop: '1rem',
  paddingInlineStart: '2rem',
  paddingInlineEnd: '1rem',
  paddingBottom: '1rem',
  extend: [
    ...parseStyleProps({ type: theme.specialPromotionStyle.fontSize, }, theme.mq, theme.type),
  ],
});

function setVariant(prop, variant, getColor) {
  return {
    backgroundColor: getColor('specialPromotions', `${variant}Bg`),
  };
}

const textStyle = ({ theme, }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  padding: theme.specialPromotionStyle.padding || '1rem 2rem',
  fontWeight: 'bold',
  color: theme.color('neutral', '-1'),
});

const IconStyle = {
  alignSelf: 'center',
  flexShrink: '0',
};

LinkElement.propTypes = {
  contentName: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  toolTip: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  site: PropTypes.string,
  body: PropTypes.string,
  kicker: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  biAction: PropTypes.object,
  includeData: PropTypes.bool,

  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
  /** The `<SpecialPromotions />`'s stylistic variant */
  variant: PropTypes.oneOfType([
    selectVariants,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: selectVariants.isRequired,
      })
    ),
  ]),
};

LinkElement.defaultProps = {
  linkText: '',
  miscStyles: null,
  variant: 'primary',
  site: 'haaretz',
  body: null,
  kicker: null,
  biAction: null,
  includeData: false,
};

function LinkElement({
  contentName,
  body,
  href,
  toolTip,
  linkText,
  miscStyles,
  variant,
  kicker,
  site,
  biAction,
  includeData, }) {
  const { css, theme, } = useFela({ miscStyles, variant, });
  const LogoName = (site === 'haaretz' || site === 'htz') ? IconAlefLogo : IconTheMarker;
  return (
    <BlockLink href={href} onClick={includeData && biAction ? biAction : undefined} miscStyles={miscStyles}>
      <div className={css(LinkElementStyle)}>
        <Section tagName="div" className={css(textStyle({ theme, }))}>
          <LogoName
            fill={theme.specialPromotionStyle[variant].icon}
            size={3}
            miscStyles={IconStyle}
          />
          <H className={css(titleStyle)}>{kicker || body}</H>
        </Section>

        <AboveBlockLink>
          {({ className, }) => (
            <div className={className} style={{ display: 'flex', }}>
              <Button
                variant={theme.specialPromotionStyle[variant].button}
                href={href}
                fontSize={theme.specialPromotionStyle.fontSize}
                miscStyles={{
                  whiteSpace: theme.specialPromotionStyle.whiteSpace,
                }}
              >
                {linkText}
              </Button>
            </div>
          )}
        </AboveBlockLink>
      </div>

    </BlockLink>
  );
}

type Props = {
    biImpression?: object,
    wrapRef?: object,
    inView?: boolean,
    includeData?: boolean,
  };

function OuterLinkElement(args: Props) {
  const { biImpression, wrapRef, inView, includeData, ...props } = args;
  useOneTime(!!includeData && !!inView && !!biImpression, () => {
    biImpression();
  });
  return includeData ? (
    <span ref={wrapRef}>
      <LinkElement {...props} includeData={includeData} />
    </span>
  ) : (
    <LinkElement {...props} includeData={includeData} />
  );
}

OuterLinkElement.defaultProps = {
  biImpression: null,
  wrapRef: null,
  inView: false,
  includeData: false,
};

export default OuterLinkElement;
