/* eslint-disable no-underscore-dangle */
import checkIsOmnyCustomPlayer from './isOmnyCustomPlayer';

export default function getArticleBodyComponentId(context) {
  if (!context) return null;

  const isOmnyCustomPlayer = checkIsOmnyCustomPlayer(context);
  const audioChannelName = isOmnyCustomPlayer
    ? context.settings?.channelName
    : null;

  const uniqueId = audioChannelName === 'htzOnTheWay'
    ? 'articleNarration'
    : isOmnyCustomPlayer
      ? context.inputTemplate
      : (context.kind || context.inputTemplate || context.tag || null);

  return uniqueId;
}
